import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule, nextTick } from '../utils/helpers';
import { Overlay, toggleOverlay } from '../utils/overlays';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarTogglerElement: document.querySelector('.navbar .navbar__toggler'),
});

export const toggleNavbar = (force?: boolean) => {
  const { navbarElement, navbarTogglerElement } = getElements();
  if (!navbarElement || !navbarTogglerElement) return;

  const isCurrentlyOpen = navbarElement.classList.contains('navbar--open');
  force = force ?? !isCurrentlyOpen;

  const isOpen = navbarElement.classList.toggle('navbar--open', force);
  toggleOverlay(Overlay.MENU, isOpen);

  navbarTogglerElement.ariaExpanded = `${isOpen}`;
};

const onNavbarTogglerClick = () => toggleNavbar();

const onBreakpointChange = () => {
  const { navbarElement } = getElements();
  if (!navbarElement) return;

  navbarElement.classList.add('navbar--no-transitions');
  toggleNavbar(false);
  nextTick(() => {
    navbarElement.classList.remove('navbar--no-transitions');
  });
};

export default defineModule(
  () => {
    const { navbarTogglerElement } = getElements();
    if (!navbarTogglerElement) return;

    navbarTogglerElement.addEventListener('click', onNavbarTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener('change', onBreakpointChange);
  },
  () => {
    const { navbarTogglerElement } = getElements();
    if (!navbarTogglerElement) return;

    navbarTogglerElement.removeEventListener('click', onNavbarTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      onBreakpointChange,
    );
  },
);
